import React, { useEffect, useState } from 'react';
import sanityClient from '../sanityClient';
import imageUrlBuilder from '@sanity/image-url';

const CERTIFICATES_PER_PAGE = 5; // Define the number of certificates per page

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

function Certificates() {
  const [certificates, setCertificates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "certificate"] | order(_createdAt desc) {
        title,
        description,
        "imageUrl": certificateImage.asset->url
      }`)
      .then((data) => {
        setCertificates(data);
        setLoading(false);
      })
      .catch(console.error);
  }, []);

  // Calculate the current certificates to display based on the page
  const indexOfLastCertificate = currentPage * CERTIFICATES_PER_PAGE;
  const indexOfFirstCertificate = indexOfLastCertificate - CERTIFICATES_PER_PAGE;
  const currentCertificates = certificates.slice(indexOfFirstCertificate, indexOfLastCertificate);

  // Calculate the total number of pages
  const totalPages = Math.ceil(certificates.length / CERTIFICATES_PER_PAGE);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="certificates">
      <h1>Certificates</h1>
      <div className="certificate-list">
        {currentCertificates.map((certificate, index) => (
          <div key={index} className="certificate-card">
            {certificate.imageUrl && (
              <img
                src={urlFor(certificate.imageUrl).width(200).url()}
                alt={certificate.title}
                className="certificate-image"
              />
            )}
            <h2 className="certificate-title">{certificate.title}</h2>
            <p className="certificate-description">{certificate.description}</p>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Certificates;
