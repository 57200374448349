import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import sanityClient from '../sanityClient';
import BlockContent from '@sanity/block-content-to-react';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

function BlogPost() {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "post" && slug.current == $slug][0]{
          title,
          content,
          coverImage,
          attachments,
          author->{
            name,
            bio,
            "profileImageUrl": profilePicture.asset->url
          }
        }`,
        { slug }
      )
      .then((data) => {
        setPost(data);
        setLoading(false);
      })
      .catch(console.error);
  }, [slug]);

  const getDownloadUrl = (attachment) => {
    const fileRef = attachment.file.asset._ref;
    const projectId = sanityClient.config().projectId;
    const dataset = sanityClient.config().dataset;

    const [, fileId, extension] = fileRef.split('-'); // Placeholder for the unused part
    return `https://cdn.sanity.io/files/${projectId}/${dataset}/${fileId}.${extension}`;
  };

  const handleDownloadClick = (attachment) => {
    setSelectedAttachment(attachment);
    setShowConfirm(true);
  };

  const confirmDownload = () => {
    setShowConfirm(false);
    const downloadUrl = getDownloadUrl(selectedAttachment);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = selectedAttachment.name || 'download';
    link.click();
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="blog-post-detail">
      <div className="post-content">
        {post.coverImage && (
          <img src={urlFor(post.coverImage).width(400).url()} alt={post.title} className="cover-image" />
        )}
        <div className="content-wrapper">
          <h1>{post.title}</h1>
          <div className="author-info">
            {post.author.profileImageUrl && (
              <img
                src={post.author.profileImageUrl}
                alt={post.author.name}
                className="author-icon"
              />
            )}
            <p>By {post.author.name}</p>
          </div>
          <div className="content">
            <BlockContent blocks={post.content} {...sanityClient.config()} />
          </div>

          {post.attachments && post.attachments.length > 0 && (
            <div className="attachments">
              <h3>Attachments</h3>
              <ul>
                {post.attachments.map((attachment, index) => (
                  <li key={index}>
                    <button onClick={() => handleDownloadClick(attachment)}>
                      {attachment.name || attachment.file.asset.originalFilename}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      {showConfirm && (
        <div className="confirm-popup">
          <div className="popup-content">
            <p>Are you sure you want to download this file?</p>
            <button onClick={confirmDownload} className="confirm-button">Yes</button>
            <button onClick={() => setShowConfirm(false)} className="cancel-button">No</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default BlogPost;
